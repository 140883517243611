import React, { useState, useEffect } from "react"
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { HashLoader } from "react-spinners"
const backend = require("../helpers/devsurvival.api.js")

export default ({ data, location }) => {
  const title = data.site.siteMetadata.title
  const [formState, setFormState] = useState({ tag: "", value: "" })
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState("")
  const [emojis, setEmojis] = useState([])

  useEffect(() => {
    backend.getEmojis().then(e => setEmojis(e))
  }, [])
  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      if (!formState.tag.includes("#")) {
        await backend.addEmojiToQueue({
          ...formState,
          tag: `#${formState.tag}`,
        })
      } else {
        await backend.addEmojiToQueue(formState)
      }

      setNotification("Emoji request added to queue!")
      setTimeout(() => setLoading(false), 500)
    } catch (err) {
      setNotification("sorry, server error. Please try again later")
      setTimeout(() => setLoading(false), 500)
    }
  }
  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  return (
    <Layout title={title} location={location}>
      <Seo title="Emoji List" />
      <div>
        <h4>🔥 Available Emojis 🦄</h4>
        <table className="emoji-list">
          <tr>
            <th>Tag</th>
            <th>Emoji</th>
          </tr>
          {emojis.map((e, i) => (
            <tr key={i}>
              <td>{e.tag}</td>
              <td>{e.value}</td>
            </tr>
          ))}
        </table>
      </div>
      {loading ? (
        <HashLoader />
      ) : (
        <div>
          <h4>📦 Emoji Request</h4>
          <form onSubmit={onSubmit} className="flex-row-start">
            {notification ? <p> {notification}</p> : ""}
            <input
              type="text"
              value={formState.tag}
              name="tag"
              onChange={onChange}
              placeholder="please enter tag as #TAG_NAME"
            />
            <input
              type="text"
              value={formState.value}
              name="value"
              onChange={onChange}
              placeholder="paste the emoji that is associated with the tag"
            />
            <button>submit</button>
          </form>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
